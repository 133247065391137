import styled, { createGlobalStyle } from 'styled-components'
import { fontsData, generateFonts } from './theme/Fonts'

export const GlobalStyles = createGlobalStyle`
  ${fontsData.map(item =>
    generateFonts(item.fontFamily, item.path, item.formats)
  )};
  
  body {
    margin: 0;
    font-family: ${({ theme }) => theme.Fonts.redHat.medium};
    &.no-scroll {
      overflow-y: hidden;
    }
  }
  
  h1, h2, h3, ul, p {
    margin: 0;
    padding: 0;
  }
  
  ul {
    list-style: none;
  }
 
  a {
    text-decoration: none;
    color: inherit;
    display: inline-block;
  }
  
  img {
    width: 100%;
    height: auto;
  }
  
  svg {
    fill: currentColor;
    display: block;
  }

  .ityped-cursor {
    font-size: inherit;
    opacity: 1;
    -webkit-animation: blink 0.3s infinite;
    -moz-animation: blink 0.3s infinite;
    animation: blink 0.3s infinite;
    animation-direction: alternate;
  }

  @keyframes blink {
    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes blink {
    100% {
      opacity: 0;
    }
  }

  @-moz-keyframes blink {
    100% {
      opacity: 0;
    }
  }
`

// TYPOGRAPHY

export const PrimaryTitle = styled.h1`
  font-size: 48px;
  font-family: ${({ theme }) => theme.Fonts.redHat.black};
  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 40px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 32px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 27px;
  }
`

export const SecondaryTitle = styled.h2`
  font-size: 27px;
  font-family: ${({ theme }) => theme.Fonts.redHat.bold};
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 21px;
  }
`

export const MainText = styled.p`
  font-size: 21px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 14px;
  }
`

export const SmallText = styled.p`
  font-size: 14px;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 12px;
  }
`

export const TextRed = styled.span<{ ref?: any }>`
  color: ${({ theme }) => theme.Colors.red};
`

export const NumberText = styled.p`
  font-size: 72px;
  font-family: ${({ theme }) => theme.Fonts.redHat.black};
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  margin-bottom: 1rem;
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 27px;
    margin-bottom: 0.5rem;
  }
`

// REUSABLE STYLES

export const Container = styled.div`
  width: calc(100% - 14rem);
  margin: 0 auto;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: calc(100% - 10rem);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    width: calc(100% - 6rem);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    width: calc(100% - 3rem);
  }
`

export const BackgroundBlack = styled.div<{ ref?: any }>`
  background: ${({ theme }) => theme.Colors.mainBlack};
  color: ${({ theme }) => theme.Colors.white};
  margin-top: 95px;
`

export const Col2 = styled.div`
  width: 50%;
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    width: 100%;
  }
`

export const Row = styled.div<{ isReverse?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${({ isReverse }) => (isReverse ? 'row-reverse' : 'row')};
  text-align: ${({ isReverse }) => (isReverse ? 'right' : 'left')};
  padding: 9.5rem 0;
  ${PrimaryTitle} {
    margin-bottom: 2.5rem;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    flex-direction: column;
    padding: 6rem 0;
    ${Col2}:first-child {
      margin-bottom: 4rem;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    ${Col2}:first-child {
      margin-bottom: 2.5rem;
    }
  }
`

// BUTTON
export const ButtonElement = styled.button`
  padding: 13px 31px;
  font-size: 21px;
  font-family: ${({ theme }) => theme.Fonts.redHat.bold};
  border: none;
  cursor: pointer;
  border-radius: 200px;
  text-decoration: none;
  display: inline-block;
  transition: 0.5s;
  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    font-size: 17px;
  }
`

export const ButtonRed = styled(ButtonElement)`
  background: ${({ theme }) => theme.Colors.red};
  color: ${({ theme }) => theme.Colors.white};
`

export const ButtonBlack = styled(ButtonElement)`
  background: ${({ theme }) => theme.Colors.mainBlack};
  color: ${({ theme }) => theme.Colors.white};
`

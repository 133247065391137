import { fontFace } from 'polished'

export const Fonts = {
  redHat: {
    medium: 'RedHat-Medium, Avenir, sans-serif',
    mediumItalic: 'RedHat-MediumItalic, Avenir, sans-serif',
    bold: 'RedHat-Bold, Avenir, sans-serif',
    black: 'RedHat-Black, Avenir, sans-serif',
  },
}

export const fontsData = [
  {
    fontFamily: 'RedHat-Medium',
    path: '/fonts/RedHat/Medium/RedHat-Medium',
    formats: ['woff2', 'woff', 'ttf'],
  },
  {
    fontFamily: 'RedHat-MediumItalic',
    path: '/fonts/RedHat/MediumItalic/RedHat-MediumItalic',
    formats: ['woff2', 'woff', 'ttf'],
  },
  {
    fontFamily: 'RedHat-Bold',
    path: '/fonts/RedHat/Bold/RedHat-Bold',
    formats: ['woff2', 'woff', 'ttf'],
  },
  {
    fontFamily: 'RedHat-Black',
    path: '/fonts/RedHat/Black/RedHat-Black',
    formats: ['woff2', 'woff', 'ttf'],
  },
]

export const generateFonts = (
  fontFamily: string,
  path: string,
  formats: string[]
) => {
  return fontFace({
    fontFamily: fontFamily,
    fontFilePath: path,
    fileFormats: formats,
  })
}

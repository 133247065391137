import React, { FunctionComponent } from 'react'
import PropTypes from 'prop-types'
import { AnimatePresence } from 'framer-motion'
import { ThemeProvider } from 'styled-components'
import { GlobalStyles, Theme } from 'styles'

interface Props {}

const Layout: FunctionComponent<Props> = ({ children }) => {
  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyles />
      <AnimatePresence exitBeforeEnter>
        <main>{children}</main>
      </AnimatePresence>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

import { Colors } from './Colors'
import { Fonts } from './Fonts'

export const Theme = {
  Colors,
  Fonts,
  breakpoints: {
    xl: 1600,
    lg: 1200,
    md: 991,
    sm: 767,
    xs: 576,
  },
}
